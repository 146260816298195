"use client";
import { Box, SvgIcon, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction } from "react";
import { breederIcon, dogface, litterIcon } from "../public/assets/icons";
import { tan } from "./Theme";
import { Type } from "./enum";

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root": {
    border: 0,
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "20px",
    backgroundColor: "white",
    textTransform: "none",
    width: "30%",
    maxWidth: "150px",
    "&:hover": {
      backgroundColor: tan[300],
      color: "white",
    },
  },
  "&.MuiToggleButtonGroup-firstButton": {
    borderRadius: "10px 0px 0px 10px",
  },
  "&.MuiToggleButtonGroup-middleButton": {
    borderRadius: "0px",
  },
  "&.MuiToggleButtonGroup-lastButton": {
    borderRadius: " 0px 10px 10px 0px",
  },
  "&.Mui-selected": {
    backgroundColor: `${tan[300]}`,
    color: "white",
  },
}));

interface FilterProps {
  filters: Type;
  setFilters: Dispatch<SetStateAction<Type>>;
  query: string;
}

const FilterButtons = ({ filters, setFilters, query }: FilterProps) => {
  const router = useRouter();
  return (
    <ToggleButtonGroup
      value={filters}
      exclusive
      onChange={(_, value) => {
        if (value !== null) {
          setFilters(value);
        }
      }}
      sx={{ alignSelf: "start", justifyContent: { lg: "space-between", md: "start" }, width: "100%", gap: { xs: "10px", md: "none" } }}
    >
      <StyledToggleButton value={Type.Breeder} onClick={() => router.replace(`/search?type=Breeder&query=${query}`)}>
        <Box>
          <SvgIcon sx={{ fontSize: { md: 35, xs: 35 } }} color="primary" inheritViewBox component={breederIcon} />
          <Typography> Oppdrettere </Typography>
        </Box>
      </StyledToggleButton>
      <StyledToggleButton value={Type.Dog} onClick={() => router.replace(`/search?type=Dog&query=${query}`)}>
        <Box>
          <SvgIcon sx={{ fontSize: { md: 35, xs: 35 } }} color="primary" inheritViewBox component={dogface} />
          <Typography> Hunder </Typography>
        </Box>
      </StyledToggleButton>
      <StyledToggleButton value={Type.Litter} onClick={() => router.replace(`/search?type=Litter&query=${query}`)}>
        <Box>
          <SvgIcon sx={{ fontSize: { md: 35, xs: 35 } }} color="primary" inheritViewBox component={litterIcon} />
          <Typography> Valpekull </Typography>
        </Box>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default FilterButtons;
