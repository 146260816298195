"use client";

import { ExpandCircleDown, FormatQuote } from "@mui/icons-material";
import { Box, Container, IconButton, Stack, styled, Typography, useMediaQuery } from "@mui/material";
import { Carousel } from "@trendyol-js/react-carousel";
import React from "react";

const styles = {
  root: {
    padding: "0 30px",
  },
  slideContainer: {
    padding: "0 10px",
  },
};

interface TestimonialCardProps {
  name: string;
  role: string;
  testimonial: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ name, role, testimonial }) => {
  return (
    <Card height={"98%"}>
      <Box>
        <Stack>
          <Typography textAlign={"center"} variant="h4">
            {name}
          </Typography>
          <Typography variant="subtitle2" fontSize={"20px"} textAlign={"center"}>
            {role}
          </Typography>
          <Box mt={"20px"} alignContent={"center"} alignItems={"center"} height={"100%"}>
            <FormatQuote color="primary" sx={{ transform: "rotate(180deg)", fontSize: "28px" }} />
            <Typography variant="body2" ml={"30px"}>
              {testimonial}
            </Typography>{" "}
          </Box>
        </Stack>
      </Box>
      <FormatQuote color="primary" sx={{ alignSelf: "end", justifySel: "end", fontSize: "28px", marginTop: "9px" }} />
    </Card>
  );
};

const Card = styled(Box)`
  border-radius: 10px;
  background-color: #fdfaf5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 31px 30px 7px 30px;
  max-width: 453px;
  font-family: Inter, sans-serif;
  color: #4e4234;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
`;

const UserReviewSection = () => {
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <Box width={"100%"} pb={"50px"}>
      <Container maxWidth="lg" component={Stack} sx={{ gap: "30px", justifyContent: "center" }}>
        <Typography variant="h2" textAlign={"center"} mb={"25px"}>
          Våre brukeres opplevelser
        </Typography>
        <Carousel
          show={isMobile ? 1 : 2.1}
          slide={1}
          leftArrow={
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", marginRight: "10px" }}>
              <IconButton sx={{ height: "fit-content" }}>
                <ExpandCircleDown color="primary" fontSize="large" sx={{ transform: "rotate(90deg)" }} />
              </IconButton>
            </Box>
          }
          rightArrow={
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", marginLeft: "10px" }}>
              <IconButton sx={{ height: "fit-content" }}>
                <ExpandCircleDown color="primary" fontSize="large" sx={{ transform: "rotate(-90deg)" }} />
              </IconButton>
            </Box>
          }
        >
          <TestimonialCard
            name={"Andrea Hammersland Vad"}
            role={"Kennel Valcoti"}
            testimonial={
              "HappyDogs er helt genialt. Som oppdretter får jeg samlet alle digitale verktøy på samme sted. Dette gir meg full kontroll og god oversikt."
            }
          />
          <TestimonialCard
            name={"Monica Eike"}
            role={"Kennel Sikuruq"}
            testimonial={
              "HappyDogs er et brukervennlig og intuitivt verktøy hvor oppdrettere kan presentere sitt oppdrett og sine hunder på en ryddig og oversiktlig måte. Jeg opplever at verktøyet har fokus på tillit og trygghet."
            }
          />
          <TestimonialCard
            name={"Dorrit Karlsen"}
            role={"Kardohill's Basenjis"}
            testimonial={
              "Fantastisk praktisk redskap for oss oppdrettere. Lettvint og greit med alt på ett sted. Forenkler prosessen for både avl og salg."
            }
          />
        </Carousel>
      </Container>
    </Box>
  );
};

export default UserReviewSection;
