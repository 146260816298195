"use client";

import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Welcome = () => {
  return (
    <Container maxWidth="lg" style={{ background: "white" }}>
      <ContentWrapper display={"flex"} flexDirection={{ xs: "column", md: "row" }}>
        <Box alignSelf={"center"}>
          <DogImage alt={"Aussie puppy"} src="/images/aussiePuppy.png" />
        </Box>
        <TextWrap>
          <Box>
            <Typography variant="h2">Velkommen til HappyDogs!</Typography>
            <Typography variant="body2">- Ditt digitale hundesamfunn.</Typography>
          </Box>
          <Box gap="30px">
            <Typography paragraph variant="body2">
              Vi skaper en sosial plattform der lidenskapen vår for firbente binder oss sammen. Enten du skal kjøpe deg hund for første
              gang, vise frem ditt renrasede hundeoppdrett eller presentere hunden din sine meritter.{" "}
            </Typography>
            <Typography paragraph variant="body2">
              Hos oss blir tobeinte og firbente verifisert, for å sikre trygghet mellom brukere og informasjonen som blir synlig på
              nettsiden. <br />
            </Typography>
            <Typography paragraph variant="body2">
              HappyDogs tilbyr deg en rekke digitale, gratis verktøy som loggføring, markedsplass, venteliste og spørreskjema.
            </Typography>
            <Typography paragraph variant="body2">
              Vårt formål er å fremme god hundevelferd, der hundene våre skal leve lange, sunne og lykkelige liv. Registrer deg og bli med i
              ditt digitale hundesamfunn!
            </Typography>
          </Box>
        </TextWrap>
      </ContentWrapper>
    </Container>
  );
};

export default Welcome;

const DogImage = styled("img")`
  height: 310px;
  @media (max-width: 850px) {
    height: 150px;
  }
`;

const ContentWrapper = styled(Box)`
  justify-content: center;
  display: flex;
  gap: 100px;
  margin-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 850px) {
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const TextWrap = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
