"use client";

import { styled } from "@mui/material/styles";

import { Box, ButtonBase, Container, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "next/link";
import { useMemo } from "react";
import SearchBar from "../Searchbar";
import IconLinkButton from "./IconLinkButton";

interface Props {
  isLoggedin: boolean;
  isBreeder: boolean;
}

const FrontImage = ({ isLoggedin, isBreeder }: Props) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const observer = useMemo(() => {
    if (typeof window !== "undefined")
      return new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      });
  }, []);

  return (
    <FullPageWrap>
      <Container maxWidth="lg">
        <Box
          mt={"25px"}
          mb="130px"
          display={"flex"}
          alignItems={"center"}
          justifyItems={"center"}
          justifyContent="space-between"
          width={"100%"}
        >
          <Box
            display={"flex"}
            gap="30px"
            flexDirection={"column"}
            width={"500px"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems="center"
          >
            <Typography
              width={"400px"}
              variant="h1"
              fontSize={"40px"}
              fontWeight={"bold"}
              textTransform={"uppercase"}
              textAlign={"center"}
              sx={{ lineHeight: "142%", letterSpacing: "2.4px" }}
            >
              Velkommen til HappyDogs
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              textAlign={"center"}
              fontSize={"24px"}
              letterSpacing={"5.28px"}
              whiteSpace="nowrap"
            >
              Ditt digitale hundeverktøy!
            </Typography>
            <Box width="100%" mt="30px" height="150px">
              <Box display="flex" position={"absolute"} flexDirection={"column"} gap="20px">
                <SearchBar focus={true} variant={"Landingpage"} />

                <Box display={"flex"} gap="20px" zIndex={200}>
                  <ButtonBase
                    href="/search?type=Dog"
                    sx={{
                      height: "90px",
                      flexShrink: "0",
                      borderRadius: "10px",
                      border: "1px solid #DBD2C8",
                      background: "#F0ECE7",
                      padding: "20px 33px",
                      width: "fit-content",
                    }}
                    LinkComponent={Link}
                  >
                    <Typography variant="body2" fontSize={"20px"}>
                      Søk etter hunder
                    </Typography>
                  </ButtonBase>
                  <ButtonBase
                    href="/search?type=Breeder"
                    sx={{
                      height: "90px",
                      flexShrink: "0",
                      borderRadius: "10px",
                      border: "1px solid #DBD2C8",
                      background: "#F0ECE7",
                      padding: "20px 33px",
                      width: "fit-content",
                    }}
                    LinkComponent={Link}
                  >
                    <Typography variant="body2" fontSize={"20px"}>
                      Søk etter oppdrettere
                    </Typography>
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      height: "90px",
                      flexShrink: "0",
                      borderRadius: "10px",
                      border: "1px solid #DBD2C8",
                      background: "#F0ECE7",
                      padding: "20px 33px",
                      width: "fit-content",
                    }}
                    href="/search?type=Litter"
                    LinkComponent={Link}
                  >
                    <Typography variant="body2" fontSize={"20px"}>
                      Søk etter valper
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"end"} justifySelf={"end"}>
            <video
              src={"/images/videoLanding.mp4"}
              autoPlay={!isMobile}
              loop
              muted
              height="769px"
              width="540px"
              style={{
                objectFit: "cover",
                borderRadius: "150px 20px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                width: "540.357px",
                height: "769.007px",
                flexShrink: 0,
                objectPosition: "center",
              }}
            />
          </Box>
        </Box>

        <IconCardWrapper>
          <IconLinkButton
            className="hidden iconlink"
            observer={observer}
            title={isBreeder ? "Oppdretterside" : "Hundeprofiler"}
            iconSrc={isBreeder ? "/assets/icons/breeder.svg" : "/assets/icons/dogface.svg"}
            description={isBreeder ? "Registrer ditt oppdrett, hunder og valpekull" : "Presenter dine hunder med egne profiler"}
            urlHref={isLoggedin ? (isBreeder ? "/me/breeder" : "/me/dogs") : "/login"}
          />
          <IconLinkButton
            className="hidden iconlink"
            observer={observer}
            title="Logg"
            iconSrc={"/assets/icons/puppylog.svg"}
            description="Før treningslogg og valpelogg"
            urlHref={isLoggedin ? (isBreeder ? "/me/log" : "/me/log/doglog") : "/login"}
          />
          <IconLinkButton
            className="hidden iconlink"
            observer={observer}
            title={"Venteliste"}
            iconSrc={"/assets/icons/waitinglist.svg"}
            description="Administrer spørreskjema og venteliste"
            urlHref={isLoggedin ? (isBreeder ? "/me/waitinglist" : "/me/applications") : "/login"}
          />
        </IconCardWrapper>
        <MobileCard>
          <IconLinkButton
            title="Utforsk"
            iconSrc={"/assets/icons/dogface.svg"}
            description="Søk etter hunder, kull og oppdrettere."
            urlHref={"/search"}
          />
        </MobileCard>
      </Container>
    </FullPageWrap>
  );
};

export default FrontImage;

const IconCardWrapper = styled("div")`
  display: flex;
  gap: 25px;
  justify-content: center;
  @media (max-width: 850px) {
    display: none;
    justify-content: center;
  }
`;

const FullPageWrap = styled("div")`
  padding-bottom: 150px;

  @media (max-width: 800px) {
    padding-bottom: 50px;
  }
`;

const MobileCard = styled("div")`
  display: none;
  gap: 25px;
  margin-bottom: -140px;

  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
  }
`;
